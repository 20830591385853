import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

import './public-path';
// import { BrowserRouter } from 'react-router-dom';
import actions from './actions';

function render(props) {
  const { container } = props
  const dom = container ? container.querySelector('#root-sub01') : document.getElementById("root-sub01")
  const root = ReactDOM.createRoot(dom)
  root.render(
    <App />
  )
}
console.log(window.__POWERED_BY_QIANKUN__, 'window.__POWERED_BY_QIANKUN__');
if (!window.__POWERED_BY_QIANKUN__) {
  console.log('error');
  render({});
}

export async function bootstrap() {
  console.log('sub01 bootstrap');
}

export async function mount(props) {
  console.log('sub01 mount', props);
  if (props) {
    actions.setActions(props)
  }
  // props.setGlobalState(newState);
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  const dom = container ? container.querySelector('#root-sub01') : document.getElementById("root-sub01")
  const root = ReactDOM.createRoot(dom)
  root.unmount(dom);
}

reportWebVitals();
