import React, { useEffect } from 'react';
import { Badge, Descriptions, Tooltip, Button } from 'antd';
import styled from 'styled-components';

import '../style/App.css';
import actions from '../actions';
import { useState } from 'react';

const ContentBox = styled('div')`
  padding: 24px;
  min-height: 360px;
  background: #fff;
  margin: 24px 16px 0;
`;

const App = () => {
  const [connectState, setConnectState] = useState('error');
  const [getTokenValue, setToken] = useState('无');

  useEffect(() => {
    console.log(window.history, 'window.history');
    actions.onGlobalStateChange((state, pre) => {
      if (state?.token) {
        console.log('-----------子应用01获取token-------------', state?.token);
        setConnectState('success');
        setToken(state?.token);
        //这里可以把state保存到子应用store
      }
    }, true)

    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);

  const jumpTo = () => {
    if (connectState) {
      window.history.pushState({ test: '子应用01过来的' }, '测试1', '/app-react2');
    }
  }

  return (
    <ContentBox>
      <Descriptions title="项目详情" bordered>
        <Descriptions.Item label="项目名称">主应用</Descriptions.Item>
        <Descriptions.Item label="前端框架">react</Descriptions.Item>
        <Descriptions.Item label="版本">18</Descriptions.Item>
        <Descriptions.Item label="项目名称">微应用01</Descriptions.Item>
        <Descriptions.Item label="前端框架">react</Descriptions.Item>
        <Descriptions.Item label="版本">18</Descriptions.Item>
        <Descriptions.Item label="创建时间">2023-03-25 18:00:00</Descriptions.Item>
        <Descriptions.Item label="完成时间" span={2}>
          2023-03-26 18:00:00
        </Descriptions.Item>
        <Descriptions.Item label="运行状态" span={1}>
          <Badge status="processing" text="running" />
        </Descriptions.Item>
        <Descriptions.Item label="通信状态" span={1}>
          <Badge status={connectState} text={connectState} />
        </Descriptions.Item>
        <Descriptions.Item label="主应用生成的token" span={1}>
          {getTokenValue}
        </Descriptions.Item>
        <Descriptions.Item label="store">redux-saga</Descriptions.Item>
        <Descriptions.Item label="mysql">
          <Badge status="processing" text="running" />
        </Descriptions.Item>
        <Descriptions.Item label="api Server">
          <Badge status="processing" text="running" />
        </Descriptions.Item>
        <Descriptions.Item label="操作" span={3}>
          {connectState === 'error' ?
            <Tooltip title="独立应用跳不过去" open>
              <Button type="primary" onClick={jumpTo}>跳转到微应用02</Button>
            </Tooltip>
            :
            <Button type="primary" onClick={jumpTo}>跳转到微应用02</Button>
          }
        </Descriptions.Item>
        <Descriptions.Item label="微应用详情">
          这个站是用qiankun微前端搭建的
          <br />
          <br />
          优点
          <br />
          是能做到多技术框架合并成一个集合例如react + vue + angular,解耦并减少包的大小,可以把ui,unit,axio,和逻辑模块单独抽出.
          <br />
          <br />
          缺点
          <br />
          路由只能由主应用统一配置,主应用和微应用通信困难,手段复杂,各应用有自己的store各自维护,但主应用除了自己的store,还要维护gobleState的store,只适合通信较少的场景,
          微应用路由跟主应用路由不是同一个东西,所以只能用history.pushState。
          <br />
          <br />
          主应用  <a href="https://wei1.y3iu.com" target="_blank" rel="noreferrer">wei1.y3iu.com</a>
          <br />
          微应用1 <a href="https://wei2.y3iu.com" target="_blank" rel="noreferrer">wei2.y3iu.com</a> 访问这个网址你可以看见 独立运行的微应用而且通信状态是断开的
          <br />
          微应用2 <a href="https://wei3.y3iu.com" target="_blank" rel="noreferrer">wei3.y3iu.com</a>
        </Descriptions.Item>
      </Descriptions>
    </ContentBox>

  );
}

export default App;
